import BaseAlert from '../components/Custom/BaseAlert.vue';
import BaseButton from '../components/Custom/BaseButton.vue';
import BaseCard from '../components/Custom/BaseCard.vue';
import BaseDropdown from '../components/Custom/BaseDropdown.vue';
import BaseInput from '../components/Custom/BaseInput.vue';
import BaseModal from '../components/Custom/BaseModal.vue';
import { FormGroupInput, Card, DropDown, Button } from "../components/index";

const GlobalComponents = {
  install(Vue) {
    Vue.component("fg-input", FormGroupInput);
    Vue.component("drop-down", DropDown);
    Vue.component("card", Card);
    Vue.component("p-button", Button);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCard.name, BaseCard);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseModal.name, BaseModal);
  }
};

export default GlobalComponents;
