<template>
  <div
    @keydown.esc="
      modals.visualizar = false;
      modals.alterar = false;
      modals.adicionar = false;
      modals.remover = false;
    "
    class="row"
  >
    <div class="col-md-12">
      <card>
        <!-- Main page title -->
        <template slot="header">
          <div class="row mt-2">
            <div class="col-lg-5 col-md-12 col-sm-12" style="padding-top: 30px;">
              <h4 class="card-title">Listagem de DNS</h4>
              <p class="card-category mt-3 mt-lg-0">
                Tabela com informações de DNS
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 30px;">
              <div>
                <button class="btn btn-success" @click="adicionarDns()">
                  <i class="ti-plus"></i>
                </button>
                <!-- <button class="btn btn-info" @click="mostrarPesquisa = mostrarPesquisa ? false : true">
                  <i class="ti-search"></i>
                </button> -->
              </div>
              <!-- <div class="row" v-show="mostrarPesquisa">
                <div class="col-md-12">
                  <select v-model="zonaPesquisa" class="w-100">
                    <option :value="null" disabled selected
                      >Selecionar o projeto</option
                    >
                    <option v-for="(zona, i) in zonas" :key="i" :value="zona">{{
                      zona.name
                    }}</option>
                  </select>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="nomePesquisa"
                    placeholder="Nome"
                  ></base-input>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-success" @click="pesquisarPeloNome()">
                    <i class="ti-search"></i>
                  </button>
                </div>
              </div> -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <label style="padding-left: 18px;">Selecionar o projeto</label>
              <div class="form-control h-auto">
                <select v-model="zonaAtual" class="w-100">
                  <option :value="null" disabled selected>
                    Selecionar o projeto
                  </option>
                  <option v-for="(zona, i) in zonas" :key="i" :value="zona">
                    {{ zona.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>
        <!-- Main page content -->
        <div class="content git-hooks pt-3">
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <div class="text-center" v-show="changing">
            <i class="ti-reload d-inline-block" id="loading"></i>
          </div>
          <!-- Tabela para listagem de dns -->
          <paper-table :class="changing ? 'desabilitado' : ''" :data="listaDns">
            <!-- Cabeçalho da tabela -->
            <template slot="columns">
              <th class="pointer" v-on:click="ordenar('type')">
                Tipo
                <i
                  v-if="ordemSeta === 'type'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('name')">
                Nome
                <i
                  v-if="ordemSeta === 'name'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('content')">
                Conteúdo
                <i
                  v-if="ordemSeta === 'content'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('ttl')">
                TTL
                <i
                  v-if="ordemSeta === 'ttl'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordernarProxied('proxied')">
                Proxiado
                <i
                  v-if="ordemSeta === 'proxied'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
            </template>
            <!-- Linhas da tabela -->
            <template slot-scope="{ row }">
              <td>{{ row.type }}</td>
              <td>
                {{
                  row.name && row.name.length > 30
                    ? row.name.substring(0, 30) + "[...]"
                    : row.name
                }}
              </td>
              <td>
                {{
                  row.content && row.content.length > 30
                    ? row.content.substring(0, 30) + "[...]"
                    : row.content
                }}
              </td>
              <td>{{ row.ttl == 1 ? "Auto" : row.ttl }}</td>
              <td><i :class="row.proxied ? 'ti-check' : 'ti-close'"></i></td>
              <td>
                <base-dropdown class="dropdown" position="right">
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ti-menu-alt"></i>
                  </a>
                  <template>
                    <a
                      href="javascript:void(0)"
                      @click="visualizarDns(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-eye mr-2"></i>Ver
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click="alterarDns(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-write mr-2"></i>Editar
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click="removerDns(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-trash mr-2"></i>Remover
                    </a>
                  </template>
                </base-dropdown>
              </td>
            </template>
          </paper-table>
          <div class="d-flex justify-content-end">
            <base-pagination
              :class="changing ? 'desabilitado' : ''"
              :value="paginacaoDns.paginaAtual"
              :total="paginacaoDns.quantidadeTotal"
              :perPage="paginacaoDns.porPagina"
              v-on:input="mudarPaginaDns"
            ></base-pagination>
          </div>
        </div>
      </card>
      <base-modal
        :show.sync="modals.visualizar"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-xl"
        type="mini"
      >
        <base-card
          shadow
          type=""
          header-classes="pb-5"
          body-classes="mb-0"
          class="border-0"
        >
          <template>
            <div class="col">
              <div class="row mb-2">
                <h4>Detalhes de {{ dnsSelecionado.name }}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">Nome: {{ dnsSelecionado.name }}</div>
              <div class="col-md-12">Tipo: {{ dnsSelecionado.type }}</div>
              <div class="col-md-12">
                Conteúdo: {{ dnsSelecionado.content }}
              </div>
              <div class="col-md-12">
                Proxiável: {{ dnsSelecionado.proxiable ? "Sim" : "Não" }}
              </div>
              <div class="col-md-12">
                Proxiado: {{ dnsSelecionado.proxied ? "Sim" : "Não" }}
              </div>
              <div class="col-md-12">
                TTL:
                {{
                  dnsSelecionado.ttl == 1 ? "Automático" : dnsSelecionado.ttl
                }}
              </div>
              <div class="col-md-12">
                Trancado (locked): {{ dnsSelecionado.locked ? "Sim" : "Não" }}
              </div>
              <div class="col-md-12">
                Criado em:
                {{
                  dnsSelecionado.created_on
                    ? new Date(dnsSelecionado.created_on).toLocaleString()
                    : null
                }}
              </div>
              <div class="col-md-12">
                Atualizado em:
                {{
                  dnsSelecionado.modified_on
                    ? new Date(dnsSelecionado.modified_on).toLocaleString()
                    : null
                }}
              </div>
              <hr />
              <div
                class="col-md-12 pointer"
                @click="mostrarInfo = mostrarInfo ? false : true"
              >
                Informações detalhadas
                <i :class="'ti-arrow-' + (mostrarInfo ? 'up' : 'down')"></i>
              </div>
              <div class="col-md-8 align-items-center" v-show="mostrarInfo">
                <pre>{{ dnsSelecionado }}</pre>
              </div>
            </div>
          </template>
        </base-card>
      </base-modal>
      <base-modal
        :show.sync="modals.adicionar"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-xl"
        type="mini"
      >
        <base-card
          shadow
          type=""
          header-classes="pb-5"
          body-classes="mb-0"
          class="border-0"
        >
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <template>
            <div class="d-flex justify-content-center mb-2">
              <h4 style="margin-top: 1rem">
                Adicionar um novo DNS em "{{ zonaAtual.name }}"
              </h4>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Tipo</label>
                <div class="form-group">
                  <select v-model="dnsPattern.type" required class="w-100">
                    <option :value="''" disabled selected>Tipo</option>
                    <option
                      v-for="(item, i) in listaTipos"
                      :key="i"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  label="Nome"
                  v-model="dnsPattern.name"
                  required
                  placeholder="Nome"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Conteúdo"
                  v-model="dnsPattern.content"
                  required
                  placeholder="Conteúdo"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Proxiado?</label>
                <div class="form-group">
                  <select v-model="dnsPattern.proxied" class="w-100">
                    <option :value="''" disabled selected>Proxiado?</option>
                    <option :value="true">Sim</option>
                    <option :value="false">Não</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  label="TTL (1 para automático)"
                  v-model="dnsPattern.ttl"
                  type="number"
                  required
                  placeholder="TTL (1 para automático)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  class="btn btn-success w-100"
                  :class="loading ? 'desabilitado' : ''"
                  @click="inserirDnsAPI()"
                >
                  Inserir DNS
                </button>
              </div>
            </div>
          </template>
        </base-card>
      </base-modal>
      <base-modal
        :show.sync="modals.alterar"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-xl"
        type="mini"
      >
        <base-card
          shadow
          type=""
          header-classes="pb-5"
          body-classes="mb-0"
          class="border-0"
        >
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <template>
            <div class="d-flex justify-content-center mb-2">
              <h4 style="margin-top: 1rem">
                Alterar um DNS em "{{ zonaAtual.name }}"
              </h4>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Tipo</label>
                <div class="form-group">
                  <select v-model="dnsPattern.type" required class="w-100">
                    <option :value="''" disabled selected>Tipo</option>
                    <option
                      v-for="(item, i) in listaTipos"
                      :key="i"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  label="Nome"
                  v-model="dnsPattern.name"
                  required
                  placeholder="Nome"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Conteúdo"
                  v-model="dnsPattern.content"
                  required
                  placeholder="Conteúdo"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Proxiado?</label>
                <div class="form-group">
                  <select v-model="dnsPattern.proxied" class="w-100">
                    <option :value="''" disabled selected>Proxiado?</option>
                    <option :value="true">Sim</option>
                    <option :value="false">Não</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  label="TTL (1 para automático)"
                  v-model="dnsPattern.ttl"
                  type="number"
                  required
                  placeholder="TTL (1 para automático)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  class="btn btn-success w-100"
                  :class="loading ? 'desabilitado' : ''"
                  @click="alterarDnsAPI()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </template>
        </base-card>
      </base-modal>
      <base-modal
        :show.sync="modals.remover"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-md"
        type="mini"
      >
        <base-card
          shadow
          type=""
          header-classes="pb-5"
          body-classes="mb-0"
          class="border-0"
        >
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <template>
            <div class="d-flex justify-content-center mb-2">
              <h4 style="margin-top: 1rem">
                Deseja mesmo remover "{{ dnsPattern.name }}" ({{
                  zonaAtual.name
                }})?
              </h4>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button
                  class="btn btn-danger w-100"
                  :class="loading ? 'desabilitado' : ''"
                  @click="removerDnsAPI()"
                >
                  Sim. Remover agora
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-info w-100"
                  :class="loading ? 'desabilitado' : ''"
                  @click="modals.remover = false"
                >
                  Não
                </button>
              </div>
            </div>
          </template>
        </base-card>
      </base-modal>
    </div>
  </div>
</template>

<script>
import { PaperTable, BasePagination } from "@/components";
import {
  LOCALSTORAGE_KEY_LOGIN,
  CLOUDFLARE_API_URL,
  CLOUDFLARE_AUTH_KEY,
  CLOUDFLARE_AUTH_EMAIL,
  LISTA_TIPOS,
} from "@/config/data";

export default {
  beforeMount() {
    let login = localStorage.getItem(LOCALSTORAGE_KEY_LOGIN);
    if (!login || login != "login@gitupdater#5550123") {
      this.$router.push({ name: "auth" });
    }
    this.comunicacaoCloudflare = new this.$communication(CLOUDFLARE_API_URL);
    this.listaTipos = LISTA_TIPOS;
    this.init();
  },
  components: { PaperTable, BasePagination },
  data: () => ({
    comunicacao: null,
    comunicacaoCloudflare: null,
    error: false,
    msgError: null,
    changing: false,
    zonas: [],
    zonaAtual: {},
    qtdZonas: 0,
    qtdTotalZonas: 0,
    modals: {
      visualizar: false,
      alterar: false,
      adicionar: false,
      remover: false,
    },
    listaDns: [],
    ordem: 1,
    ordemSeta: "nome",
    paginacaoDns: {
      paginaAtual: 1,
      porPagina: 20,
      totalPaginas: null,
      quantidadeTotal: null,
    },
    dnsSelecionado: {},
    dnsPattern: {
      id: "",
      name: "",
      content: "",
      type: "",
      ttl: "",
      proxied: "",
    },
    listaTipos: [],
    mostrarInfo: false,
    mostrarPesquisa: false,
    zonaPesquisa: "",
    nomePesquisa: "",
    loading: false,
  }),
  methods: {
    resetarPadrao() {
      this.dnsPattern = {
        id: "",
        name: "",
        content: "",
        type: "",
        ttl: "",
        proxied: "",
      };
    },

    limparListagem() {
      this.paginacaoDns.paginaAtual = 1;
      this.listaDns = [];
    },

    changeZona(zona) {
      this.limparListagem();
      this.listarDns(zona, 1);
    },

    changePagina(zona, pagina) {
      this.listarDns(zona, pagina);
    },

    async inserirDnsAPI() {
      if (
        this.dnsPattern.name == "" ||
        this.dnsPattern.type == "" ||
        this.dnsPattern.content == "" ||
        Number(this.dnsPattern.ttl) <= 0
      ) {
        this.msgError =
          "Favor, preencher todos os campos obrigatórios: nome, tipo, conteúdo e ttl (deve ser um número positivo).";
        this.error = true;
        return false;
      }
      let dadosPost = {
        name: this.dnsPattern.name,
        type: this.dnsPattern.type,
        content: this.dnsPattern.content,
        ttl: this.dnsPattern.ttl,
      };

      if (this.dnsPattern.proxied !== "") {
        dadosPost.proxied = this.dnsPattern.proxied;
      }
      this.loading = true;
      let r = await this.comunicacaoCloudflare.send(
        `/registro/${this.zonaAtual.id}`,
        "POST",
        null,
        dadosPost,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.loading = false;
      if ([200, 201].includes(r.status)) {
        this.fireToast("success", "DNS adicionado com sucesso!");
        this.changePagina(this.zonaAtual, this.paginacaoDns.paginaAtual);
        this.modals.adicionar = false;
      } else {
        this.msgError =
          r.data.mensagem ||
          "Não foi possível inserir o registro... Verifique os dados ou tente novamente mais tarde";
        this.error = true;
      }
      return r;
    },

    async alterarDnsAPI() {
      if (
        this.dnsPattern.id == "" ||
        this.dnsPattern.name == "" ||
        this.dnsPattern.type == "" ||
        this.dnsPattern.content == "" ||
        Number(this.dnsPattern.ttl) <= 0
      ) {
        this.msgError =
          "Favor, preencher todos os campos obrigatórios: nome, tipo, conteúdo e ttl (deve ser um número positivo).";
        this.error = true;
        return false;
      }
      let dadosPut = {
        name: this.dnsPattern.name,
        type: this.dnsPattern.type,
        content: this.dnsPattern.content,
        ttl: this.dnsPattern.ttl,
      };

      if (this.dnsPattern.proxied !== "") {
        dadosPut.proxied = this.dnsPattern.proxied;
      }
      this.loading = true;
      let r = await this.comunicacaoCloudflare.send(
        `/registro/${this.zonaAtual.id}/${this.dnsPattern.id}`,
        "PUT",
        null,
        dadosPut,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.loading = false;
      if ([200, 201].includes(r.status)) {
        this.fireToast("success", "DNS alterado com sucesso!");
        this.changePagina(this.zonaAtual, this.paginacaoDns.paginaAtual);
        this.modals.alterar = false;
      } else {
        this.msgError =
          r.data.mensagem ||
          "Não foi possível alterar o registro... Verifique os dados ou tente novamente mais tarde";
        this.error = true;
      }
      return r;
    },

    async removerDnsAPI() {
      if (this.dnsPattern.id == "") {
        this.msgError = "Favor, selecione o DNS que deseja remover";
        this.error = true;
        return false;
      }

      this.loading = true;
      let r = await this.comunicacaoCloudflare.send(
        `/registro/${this.zonaAtual.id}/${this.dnsPattern.id}`,
        "DELETE",
        null,
        null,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.loading = false;
      if ([200, 201].includes(r.status)) {
        this.fireToast("success", "DNS removido com sucesso!");
        this.changePagina(this.zonaAtual, this.paginacaoDns.paginaAtual);
        this.modals.remover = false;
      } else {
        this.msgError =
          r.data.mensagem ||
          "Não foi possível remover o registro... Verifique os dados ou tente novamente mais tarde";
        this.error = true;
      }
      return r;
    },

    async pesquisarPeloNome(zona, i) {
      this.changing = true;
      let r = await this.comunicacaoCloudflare.send(
        `/registros/${this.zonaPesquisa}?name=${this.nomePesquisa}`,
        "GET",
        null,
        null,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.changing = false;
      if (r.status == 200) {
        this.listaDns = r.data.dados.result;
        this.paginacaoDns.quantidadeTotal =
          r.data.dados.result_info.total_count;
      }
      return r;
    },

    async listarDns(zona, i) {
      this.changing = true;
      let r = await this.comunicacaoCloudflare.send(
        `/registros/${zona.id}/${this.paginacaoDns.porPagina}/${i}`,
        "GET",
        null,
        null,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.changing = false;
      if (r.status == 200) {
        this.listaDns = r.data.dados.result;
        this.paginacaoDns.quantidadeTotal =
          r.data.dados.result_info.total_count;
      }
      return r;
    },

    async listarZonas(i) {
      this.changing = true;
      let r = await this.comunicacaoCloudflare.send(
        `/zonas/50/${i}`,
        "GET",
        null,
        null,
        {
          "auth-key": CLOUDFLARE_AUTH_KEY,
          "auth-email": CLOUDFLARE_AUTH_EMAIL,
        }
      );
      this.changing = false;
      if (r.status == 200) {
        [].push.apply(this.zonas, r.data.dados.result);
        this.qtdTotalZonas = r.data.dados.result_info.total_count;
        this.qtdZonas += r.data.dados.result_info.count;
        if (this.qtdTotalZonas > 50 && this.qtdZonas < this.qtdTotalZonas) {
          await this.listarZonas(i + 1);
        }
        this.zonaAtual = this.zonas[Object.keys(r.data.dados.result)[0]];
      } else {
        this.zonaAtual = {};
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do Cloudflare! Por favor tente novamente.";
      }
      return r;
    },

    async init() {
      await this.listarZonas(1);
    },

    ordenar(key) {
      if (this.ordemAtual === key) {
        this.ordem = -1;
        this.ordemAtual = "";
        this.ordemSeta = key;
      } else {
        this.ordem = 1;
        this.ordemAtual = key;
        this.ordemSeta = key;
      }
      this.listaDns = this.listaDns.sort(
        this.$objetos.ordernarArray(key, this.ordem)
      );
    },

    ordernarProxied(key) {
      if (this.ordemAtual === key) {
        this.ordem = -1;
        this.ordemAtual = "";
        this.ordemSeta = key;
      } else {
        this.ordem = 1;
        this.ordemAtual = key;
        this.ordemSeta = key;
      }
      this.listaDns = this.listaDns.sort((a, b) => {
        if (a.proxied && !b.proxied) {
          return this.ordem;
        }
        if (!a.proxied && b.proxied) {
          return this.ordem === 1 ? -1 : 1;
        }
      });
    },

    visualizarDns(dns) {
      this.resetarPadrao();
      this.dnsSelecionado = dns;
      this.modals.visualizar = true;
    },

    adicionarDns() {
      this.resetarPadrao();
      this.modals.adicionar = true;
    },

    alterarDns(dns) {
      this.resetarPadrao();
      this.dnsPattern = {
        id: dns.id,
        type: dns.type,
        name: dns.name,
        content: dns.content,
        proxied: dns.proxied,
        ttl: dns.ttl,
      };
      this.modals.alterar = true;
    },

    removerDns(dns) {
      this.resetarPadrao();
      this.dnsPattern = {
        id: dns.id,
        type: dns.type,
        name: dns.name,
        content: dns.content,
        proxied: dns.proxied,
        ttl: dns.ttl,
      };
      this.modals.remover = true;
    },

    mudarPaginaDns(pagina) {
      this.paginacaoDns.paginaAtual = pagina;
      this.changePagina(this.zonaAtual, pagina);
    },

    fireToast(icon, title, toastTimeDuration = 3000) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: toastTimeDuration,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({ icon, title });
    },
  },
  name: "Subdomains",
  watch: {
    zonaAtual: async function (value) {
      this.changeZona(value);
    },
    error: function (error) {
      if (error) {
        setTimeout(() => {
          this.error = false;
          this.msgError = null;
        }, this.msgError.length * 75);
      }
    },
  },
};
</script>

<style scoped>
select {
  background-color: white;
  border-width: 2px;
  height: 41px;
  border-radius: 20px;
}

option {
  padding-right: 30px;
}

.pointer {
  cursor: pointer;
}

#loading {
  transform: rotate(0deg);
  animation-name: girar;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: none;
    width: 100%;
    padding: 0 20px;
  }
  .card .card-body {
    padding: 1px 4px 10px 4px;
  }
}
</style>
