<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul>
          <li>
            <router-link :to="{path:'/githooks'}">Gitupdater UI</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright d-flex flex-wrap">
        &copy; Versão: {{ APPVERSION }}
      </div>
    </div>
  </footer>
</template>
<script>
import pj from '../../../package.json';

export default {
  data: () => ({
    APPVERSION: pj.version
  })
};
</script>
<style>
</style>
