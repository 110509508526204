<template>
  <div
    @keydown.esc="
      modals.view = false;
      modals.edit = false;
      modals.create = false;
      modals.filterConfigs = false;
    "
    class="row"
  >
    <div class="col-md-12">
      <card>
        <!-- Main page title -->
        <template slot="header">
          <div class="row mt-2">
            <div class="col-lg-6 col-md-12 col-sm-12 pt-3">
              <h4 class="card-title">Listagem de repositórios</h4>
              <p class="card-category mt-3 mt-lg-0">
                Tabela contendo todos os repositórios da NSCValore que enviam
                git hooks.
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <label style="padding-left: 18px;">Servidor</label>
              <div class="form-control">
                <select
                  v-model="filtro.servidorAtual"
                  class="w-100"
                  @change="preventToaster"
                >
                  <option :value="null" selected>Todos</option>
                  <option
                    v-for="(server, i) in servidores"
                    :key="i"
                    :value="i"
                    >{{ i }}</option
                  >
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-11 col-sm-11 pt-3">
              <base-button
                @click="createHook"
                class="btn-primary w-100 mt-lg-1 mt-3"
                >+ Incluir</base-button
              >
            </div>
            <div
              class="col-lg-1 col-md-12 pt-3"
              v-tooltip.top-start="'Configurações de filtragem'"
            >
              <base-button
                @click="modals.filterConfigs = true"
                class="btn-dark w-100 mt-lg-1 mt-3"
              >
                <i class="ti-settings"></i>
              </base-button>
            </div>
          </div>
        </template>

        <!-- Main page content -->
        <div class="content git-hooks pt-3">
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>

          <!-- Tabela para listagem dos repositórios -->
          <paper-table :data="dadosFiltrados">
            <!-- Cabeçalho da tabela -->
            <template slot="columns">
              <th class="pointer" v-on:click="ordenar('chave')">
                Identificação
                <i
                  v-if="ordemSeta === 'chave'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('servidor')">
                Servidor
                <i
                  v-if="ordemSeta === 'servidor'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('diretorio')">
                Diretório
                <i
                  v-if="ordemSeta === 'diretorio'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th class="pointer" v-on:click="ordenar('aplicacao')">
                Tipo
                <i
                  v-if="ordemSeta === 'aplicacao'"
                  :class="'ti-arrow-' + (ordem === 1 ? 'up' : 'down')"
                ></i>
              </th>
              <th></th>
            </template>

            <!-- Linhas da tabela -->
            <template slot-scope="{ row }">
              <td class="align-middle" :class="{ 'text-danger': row.inativo }">
                <i class="ti-close" v-if="row.inativo"></i>
                {{ row.chave }}
              </td>

              <td>{{ row.servidor }}</td>

              <td
                v-tooltip.bottom="
                  row.diretorio.length > 21 ? row.diretorio : false
                "
              >
                {{
                  row.diretorio.length > 21
                    ? row.diretorio.substring(0, 19).trim() + "..."
                    : row.diretorio
                }}
              </td>

              <th>{{ row.aplicacao ? "Aplicação" : "Site" }}</th>

              <td>
                <base-dropdown class="dropdown" position="right">
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ti-menu-alt"></i>
                  </a>
                  <template>
                    <!-- Edit option -->
                    <a
                      href="javascript:void(0)"
                      @click="editHook(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-pencil mr-2"></i>Editar
                    </a>

                    <!-- None Option -->
                    <a
                      href="javascript:void(0)"
                      @click="cloneHook(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-files mr-2"></i>Clonar
                    </a>

                    <!-- Refresh Option -->
                    <a
                      href="javascript:void(0)"
                      @click="updateHook(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-reload mr-2"></i>Atualizar
                    </a>
                  </template>
                </base-dropdown>
              </td>
            </template>
          </paper-table>
        </div>
      </card>
    </div>

    <!-- Modal de editar git hook -->
    <base-modal
      :show.sync="modals.edit"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template v-if="!deleteId">
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Editar GitHook</h4>
          </div>

          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>

          <!-- Formulário de edição -->
          <form role="form">
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Informações</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>

            <!-- Identificação e diretório -->
            <div class="row">
              <div class="col-6">
                <base-input
                  label="Identificação"
                  disabled
                  v-model="hookAtual.chave"
                  placeholder="Identificação"
                ></base-input>
              </div>
              <div class="col-6">
                <base-input
                  label="Diretório"
                  v-model="hookAtual.diretorio"
                  placeholder="Diretório"
                ></base-input>
              </div>
            </div>

            <!-- Servidor e tipo -->
            <div class="row">
              <div class="col-6">
                <base-input
                  label="Servidor"
                  v-model="hookAtual.servidor"
                  placeholder="Servidor"
                ></base-input>
              </div>
              <div class="col-6">
                <label>Tipo</label>
                <div class="form-control ">
                  <select
                    label="Tipo"
                    class="w-100 select-box-transparent"
                    v-model="hookAtual.aplicacao"
                  >
                    <option value disabled selected>Tipo</option>
                    <option
                      v-for="{ value, desc } in tipos"
                      v-bind:key="desc"
                      :value="value"
                      >{{ desc }}</option
                    >
                  </select>
                </div>
              </div>
            </div>

            <!-- Entrypoint e Env -->
            <div class="row" v-if="hookAtual.aplicacao">
              <div class="col-6">
                <base-input
                  label="Entrypoint"
                  v-model="hookAtual.inicializador"
                  placeholder="Entrypoint"
                ></base-input>
              </div>
              <div class="col-6">
                <base-input
                  label="Quantidade de nucleos"
                  v-model="hookAtual.nodes"
                  placeholder="Quantidade de nucleos"
                ></base-input>
              </div>
              <div class="col-12">
                <base-input
                  label="Env ou ID da Configuração"
                  v-model="hookAtual.env"
                  placeholder="Env ou ID da Configuração"
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <input
                  type="checkbox"
                  id="inativo-editar"
                  v-model="hookAtual.inativo"
                />
                <label class="ml-1" for="inativo-editar">Inativo</label>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Comandos</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>
            <!-- Comandos de atualização e criação. -->
            <div class="row mb-2">
              <div class="col-6">
                <!-- Update commands dinamic fields -->
                <div
                  v-for="(update, i) in hookAtual.update"
                  :key="i"
                  class="col-12 d-flex p-0"
                >
                  <div class="col-8 p-0">
                    <base-input placeholder="Comando" v-model="update.value" />
                  </div>
                  <div class="col-4 p-0 d-flex justify-content-end">
                    <base-button
                      class="btn-danger btn-mb-reg"
                      @click="deleteUpdate(i)"
                      >Remover</base-button
                    >
                  </div>
                </div>
                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addUpdate">
                    + Comando de Atualização
                  </base-button>
                </div>
              </div>
              <div class="col-6">
                <!-- Create commands dinamic fields -->
                <div
                  v-for="(create, i) in hookAtual.create"
                  :key="i"
                  class="col-12 d-flex p-0"
                >
                  <div class="col-8 p-0">
                    <base-input placeholder="Comando" v-model="create.value" />
                  </div>
                  <div class="col-4 p-0 d-flex justify-content-end">
                    <base-button
                      class="btn-danger btn-mb-reg"
                      @click="deleteCreate(i)"
                      >Remover</base-button
                    >
                  </div>
                </div>
                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addCreate">
                    + Comando de inicializaçao
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Ações</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>
            <!-- Botão para atualizar githook -->
            <div class="row">
              <div class="col-12">
                <base-button @click="saveHook" class="btn-secondary w-100 mb-2"
                  >Salvar</base-button
                >
                <base-button
                  @click="deleteId = hookAtual.chave"
                  class="btn-danger w-100"
                  >Excluir</base-button
                >
              </div>
            </div>
          </form>
        </template>
        <template v-else>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Remover GitHook</h4>
            <span
              >Tem certeza? Ao remover os dados não será possível
              retornar!</span
            >
          </div>
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <!-- Botões de confirmação da modal -->
          <div class="text-center text-muted">
            <div class="row justify-content-center">
              <base-button class="mr-2" @click="deleteHook" type="success"
                >Sim</base-button
              >
              <base-button @click="deleteId = null" type="danger"
                >Não</base-button
              >
            </div>
          </div>
        </template>
      </base-card>
    </base-modal>

    <!-- Modal para incluir githook -->
    <base-modal
      :show.sync="modals.create"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Incluir GitHook</h4>
          </div>

          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>

          <!-- Formulário de inclusão -->
          <form role="form">
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Informações</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>

            <!-- Identificação e diretório -->
            <div class="row">
              <div class="col-6">
                <base-input
                  label="Identificação"
                  v-model="hookAtual.chave"
                  placeholder="Identificação"
                ></base-input>
              </div>

              <div class="col-6">
                <base-input
                  label="Diretório"
                  v-model="hookAtual.diretorio"
                  placeholder="Diretório"
                ></base-input>
              </div>
            </div>

            <!-- Servidor e tipo -->
            <div class="row">
              <div class="col-6">
                <base-input
                  label="Servidor"
                  v-model="hookAtual.servidor"
                  placeholder="Servidor"
                ></base-input>
              </div>

              <div class="col-6">
                <label>Tipo</label>
                <div class="form-control ">
                  <select
                    class="w-100 select-box-transparent"
                    v-model="hookAtual.aplicacao"
                  >
                    <option value disabled selected>Tipo</option>
                    <option
                      v-for="{ value, desc } in tipos"
                      v-bind:key="desc"
                      :value="value"
                      >{{ desc }}</option
                    >
                  </select>
                </div>
              </div>
            </div>

            <!-- Entrypoint e Env -->
            <div class="row" v-if="hookAtual.aplicacao">
              <div class="col-6">
                <base-input
                  label="Entrypoint"
                  v-model="hookAtual.inicializador"
                  placeholder="Entrypoint"
                ></base-input>
              </div>
              <div class="col-6">
                <base-input
                  label="Quantidade de nucleos"
                  v-model="hookAtual.nodes"
                  placeholder="Quantidade de nucleos"
                ></base-input>
              </div>
              <div class="col-12">
                <base-input
                  label="Env ou ID da Configuração"
                  v-model="hookAtual.env"
                  placeholder="Env ou ID da Configuração"
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <input
                  type="checkbox"
                  id="inativo-criar"
                  v-model="hookAtual.inativo"
                />
                <label class="ml-1" for="inativo-criar">Inativo</label>
              </div>
            </div>

            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Comandos</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>

            <!-- Comandos de atualização e criação. -->
            <div class="row mb-2">
              <div class="col-6">
                <!-- Update commands dinamic fields -->
                <div
                  v-for="(update, i) in hookAtual.update"
                  :key="i"
                  class="col-12 d-flex p-0"
                >
                  <div class="col-8 p-0">
                    <base-input placeholder="Comando" v-model="update.value" />
                  </div>
                  <div class="col-4 p-0 d-flex justify-content-end">
                    <base-button
                      class="btn-danger btn-mb-reg"
                      @click="deleteUpdate(i)"
                      >Remover</base-button
                    >
                  </div>
                </div>

                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addUpdate">
                    + Comando de Atualização
                  </base-button>
                </div>
              </div>

              <div class="col-6">
                <!-- Create commands dinamic fields -->
                <div
                  v-for="(create, i) in hookAtual.create"
                  :key="i"
                  class="col-12 d-flex p-0"
                >
                  <div class="col-8 p-0">
                    <base-input placeholder="Comando" v-model="create.value" />
                  </div>
                  <div class="col-4 p-0 d-flex justify-content-end">
                    <base-button
                      class="btn-danger btn-mb-reg"
                      @click="deleteCreate(i)"
                      >Remover</base-button
                    >
                  </div>
                </div>

                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addCreate">
                    + Comando de inicializaçao
                  </base-button>
                </div>
              </div>
            </div>

            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <p class="small text-uppercase p-0 m-0 mr-3 category">
                  <strong>Ações</strong>
                </p>
                <hr class="w-100" />
              </div>
            </div>

            <!-- Botão para atualizar githook -->
            <div class="row">
              <div class="col-12">
                <base-button @click="saveHook" class="btn-secondary w-100"
                  >Criar</base-button
                >
              </div>
            </div>
          </form>
        </template>
      </base-card>
    </base-modal>

    <!-- Modal para incluir githook -->
    <base-modal
      :show.sync="modals.filterConfigs"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Configurações de Filtragem</h4>
          </div>

          <!-- Corpo do Modal -->
          <div>
            <div class="row">
              <div
                class="col-3"
                v-tooltip.top="
                  'Ao ativar essa opção a listagem passará a mostrar ativos e inativos'
                "
              >
                <input
                  type="checkbox"
                  id="filtrar-inativos"
                  v-model="filtro.exibirInativos"
                />
                <label class="ml-1" for="filtrar-inativos"
                  >Mostrar inativos</label
                >
              </div>
            </div>
          </div>
        </template>
      </base-card>
    </base-modal>
  </div>
</template>

<script>
import { PaperTable } from "@/components";
import { GITUPDATER_API_URL, LOCALSTORAGE_KEY_LOGIN } from "@/config/data";

export default {
  beforeMount() {
    // Auth...
    let login = localStorage.getItem(LOCALSTORAGE_KEY_LOGIN);
    if (!login || login != "login@gitupdater#5550123") {
      this.$router.push({ name: "auth" });
    }
    // Services...
    this.comunicacao = new this.$communication(GITUPDATER_API_URL);
    this.init();
  },

  mounted() {
    let filtroFromLocalStorage = localStorage.getItem("filtro");

    // Caso tenha filtro do localStorage, temos que comparar eles
    // pra ver se tem algo que está no local storage e não na instância do Vue, assim o toast
    // de filtro alterado não aparece atoa
    if (filtroFromLocalStorage) {
      filtroFromLocalStorage = JSON.parse(filtroFromLocalStorage);

      for (let chaveFiltro of Object.keys(filtroFromLocalStorage)) {
        if (filtroFromLocalStorage[chaveFiltro] !== this.filtro[chaveFiltro]) {
          this.filtro = filtroFromLocalStorage;
          break;
        }
      }
    } else {
      localStorage.setItem("filtro", JSON.stringify(this.filtro));
    }
  },

  components: { PaperTable },

  data: () => ({
    comunicacao: null,
    deleteId: null,
    servidores: [],
    error: false,
    loading: false,

    hookAtual: {
      chave: "",
      aplicacao: false,
      nodes: "max",
      update: [],
      create: [],
      inicializador: null,
      diretorio: "",
      servidor: "",
      env: null,
      inativo: false
    },

    modals: {
      create: false,
      edit: false,
      view: false,
      filterConfigs: false,
      passwordIncludeCloneteHook: false
    },

    msgError: null,
    repositorios: {},

    tipos: [{ desc: "Aplicacao", value: true }, { desc: "Site", value: false }],
    dados: [],

    repositoriosData: [],
    ordem: 1,
    ordemAtual: "",
    ordemSeta: "chave",

    dadosFiltrados: [],
    filtro: {
      exibirInativos: false,
      servidorAtual: null
    }
  }),

  methods: {
    preventToaster() {
      this.fireToast("info", "Filtro alterado com sucesso");
    },
    addCreate: function() {
      this.hookAtual.create.push({ value: "" });
    },

    addUpdate: function() {
      this.hookAtual.update.push({ value: "" });
    },

    createHook: function() {
      const password = prompt("Digite a senha para criar um repositório:");

      if (password === null) return null;

      if (!this.confirmPassword(password)) {
        return null;
      }

      this.clearHook();
      this.deleteId = null;
      this.modals.create = true;
    },

    cloneHook: function(hook) {
      const password = prompt("Digite a senha para clonar um repositório:");

      if (password === null) return null;

      if (!this.confirmPassword(password)) {
        return null;
      }

      // Formating update data.
      let formattedUpdates = hook.update.map(u => {
        return { value: u };
      });
      // Formating create data.
      let formattedCreates = hook.create.map(u => {
        return { value: u };
      });
      this.hookAtual = { ...hook };
      // Formatting atual hook.
      this.hookAtual.update = formattedUpdates;
      this.hookAtual.create = formattedCreates;
      // Limpando dados do formulario.
      this.deleteId = null;
      this.modals.create = true;
    },

    deleteCreate: function(index) {
      this.hookAtual.create.splice(index, 1);
    },

    deleteHook: async function() {
      let resp = await this.comunicacao.send(
        "/configuracoes/apagarRepositorio",
        "DELETE",
        null,
        {
          repositorio: this.deleteId
        }
      );
      if (resp.status == 200) {
        this.clearHook();
        await this.init();
        this.modals.edit = false;
      } else {
        this.error = true;
        this.msgError =
          resp.data.mensagem ||
          "Ocorreu um erro desconhecido durante a requisição!";
      }
    },

    deleteUpdate: function(index) {
      this.hookAtual.update.splice(index, 1);
    },

    editHook: function(hook) {
      // Formating update data.
      let formattedUpdates = hook.update.map(u => {
        return { value: u };
      });
      // Formating create data.
      let formattedCreates = hook.create.map(u => {
        return { value: u };
      });
      this.hookAtual = { ...hook };
      // Formatting atual hook.
      if (!this.hookAtual.nodes) this.hookAtual.nodes = "max";
      this.hookAtual.update = formattedUpdates;
      this.hookAtual.create = formattedCreates;
      this.deleteId = null;
      this.modals.edit = true;
    },

    init: async function() {
      let server = await this.comunicacao.send(
        "/configuracoes/servidores",
        "GET"
      );

      if (server.status == 200) {
        this.servidores = server.data.retorno;
      } else {
        this.error = true;
        this.msgError = "Ocorreu um erro durante a recuperação dos servidores";
      }
      let resp = await this.comunicacao.send(
        "/configuracoes/repositorios",
        "GET"
      );
      if (resp.status == 200) {
        this.repositorios = resp.data.retorno;
        this.getDados();
        this.execOrdenar();
        this.execFiltro();
      } else {
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação dos repositórios no endpoint do GitUpdater! Por favor tente novamente.";
      }
    },

    saveHook: async function() {
      // Validando informacoes.
      if (!this.hookAtual.chave || this.hookAtual.chave.length == 0)
        this.error = true;
      if (typeof this.hookAtual.aplicacao != "boolean") this.error = true;
      if (!this.hookAtual.update || this.hookAtual.update.length == 0)
        this.error = true;
      if (!this.hookAtual.create || this.hookAtual.create.length == 0)
        this.error = true;
      if (!this.hookAtual.diretorio || this.hookAtual.diretorio.length == 0)
        this.error = true;
      if (!this.hookAtual.servidor || this.hookAtual.servidor.length == 0)
        this.error = true;
      if (this.error) {
        this.msgError =
          "Há erros de válidação na sua requisição! Por favor preencha os dados corretamente.";
        return;
      }
      // Formatando arrays.
      let arrayFormatado1 = this.hookAtual.update.map(u => {
        return u.value;
      });
      let arrayFormatado2 = this.hookAtual.create.map(u => {
        return u.value;
      });
      // Objeto para requisicao.
      let dados = {
        repositorio: this.hookAtual.chave,
        dados: {
          aplicacao: this.hookAtual.aplicacao,
          update: arrayFormatado1,
          create: arrayFormatado2,
          diretorio: this.hookAtual.diretorio,
          servidor: this.hookAtual.servidor,
          inicializador: this.hookAtual.inicializador,
          env: this.hookAtual.env,
          nodes: this.hookAtual.nodes,
          inativo: this.hookAtual.inativo
        }
      };
      // Cliente http.
      let resp = await this.comunicacao.send(
        "/configuracoes/gravarRepositorio",
        "POST",
        null,
        dados
      );
      if (resp.status == 201 || resp.status == 200) {
        this.clearHook();
        await this.init();
        this.modals.create = false;
        this.modals.edit = false;
      } else {
        this.error = true;
        this.msgError =
          resp.data.mensagem ||
          "Ocorreu um erro desconhecido durante a requisição!";
      }
    },

    viewHook: function(hook) {
      this.hookAtual = { ...hook };
      this.deleteId = null;
      this.modals.view = true;
    },

    clearHook: function() {
      this.hookAtual = {
        chave: "",
        aplicacao: false,
        update: [],
        create: [],
        inicializador: null,
        diretorio: "",
        servidor: "",
        nodes: "max",
        env: null
      };
    },

    updateHook: async function(row) {
      let nomeServidor = row.servidor;
      let nomeRepositorio = row.chave;

      this.loading = true;

      let server = await this.comunicacao.send(
        "/configuracoes/servidores",
        "GET"
      );

      if (server.status == 200) {
        let servidores = server.data.retorno;
        let servidorAtual = servidores[nomeServidor];

        let req = await this.comunicacao.send(
          servidorAtual.entrypoint + "atualizador/atualizar",
          "POST",
          null,
          { nomeRespositorio: nomeRepositorio }
        );

        if (req.status == 200) {
          this.loading = false;
          this.fireToast("success", "Hook atualizado com sucesso");
        } else {
          this.error = true;
          this.msgError =
            "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
        }
      } else {
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }
    },

    getDados: function() {
      let chaves = Object.keys(this.repositorios);
      this.repositoriosData = [];
      let data = {};
      let retorno = [];
      for (let i in chaves) {
        data[chaves[i]] = this.repositorios[chaves[i]];
        data[chaves[i]].chave = chaves[i];
        this.repositoriosData.push(data[chaves[i]]);
      }
      this.dados = this.repositoriosData;
    },

    ordenar(key) {
      this.ordemSeta = key;
      if (this.ordemAtual === key) {
        this.ordem = -1;
        this.ordemAtual = "";
      } else {
        this.ordem = 1;
        this.ordemAtual = key;
      }
      if (key === "aplicacao") {
        let ordemAplicacaoValue = this.ordem == 1 ? -1 : 1;
        this.dadosFiltrados = this.dados.sort((a, b) => {
          if (a["aplicacao"] && !b["aplicacao"]) {
            return this.ordem;
          }
          if (!a["aplicacao"] && b["aplicacao"]) {
            return ordemAplicacaoValue;
          }
          return 0;
        });
      } else {
        this.dadosFiltrados = this.dados.sort(
          this.$objetos.ordernarArray(key, this.ordem)
        );
      }
    },

    execOrdenar() {
      if (this.ordemSeta) {
        var key = this.ordemSeta;
        this.ordemSeta = "";

        if (this.ordem === -1) {
          this.ordemAtual = key;
        } else {
          this.ordemAtual = "";
        }
        this.ordenar(key);
      }
    },

    execFiltro() {
      const filtro = this.filtro;

      // Filtro da parte de inativos
      const filtroInativos = () => {
        if (!filtro.exibirInativos) {
          this.dadosFiltrados = this.dados.filter(
            hook => hook.inativo === false
          );
        } else {
          this.dadosFiltrados = this.dados;
        }
      };

      // Filtro do select de servidores
      const filtroServidores = () => {
        if (filtro.servidorAtual) {
          this.dadosFiltrados = this.dadosFiltrados.filter(
            hook => hook.servidor === filtro.servidorAtual
          );
        } else {
          this.dadosFiltrados = this.dados;
          filtroInativos();
        }
      };

      filtroInativos();
      filtroServidores();
    },

    fireToast(icon, title, toastTimeDuration = 3000) {
      const Toast = this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: toastTimeDuration,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          }
        })
        .fire({ icon, title });
    },

    confirmPassword(password) {
      if (!password) {
        alert("A senha não pode ser vazia!");
        return false;
      }

      if (password !== "NscPolgo@2024Hooks") {
        alert("Senha incorreta!");
        return false;
      }

      return true;
    }
  },

  name: "Githooks",

  watch: {
    error: function(error) {
      if (error) {
        setTimeout(() => {
          this.error = false;
          this.msgError = null;
        }, this.msgError.length * 75);
      }
    },

    loading(newValue) {
      if (newValue) {
        this.fireToast("info", "Carregando...", 15000);
      }
    },

    filtro: {
      deep: true,

      handler() {
        localStorage.setItem("filtro", JSON.stringify(this.filtro));
        this.execFiltro();
      }
    }
  }
};
</script>

<style scoped>
.btn-mb-reg {
  margin-bottom: 1rem !important;
}

.select-box-transparent {
  background-color: transparent !important;
  border: none !important;
  color: #66615b !important;
}
select {
  background-color: white;
  border-width: 2px;
  height: 25px;
  border-radius: 20px;
}
option {
  padding-right: 30px;
}
.form-control {
  background-color: #fffcf5 !important;
}

.form-control:focus {
  border: 1px solid #7a9e9f !important;
}
.pointer {
  cursor: pointer;
}
</style>
