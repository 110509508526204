/**
 * @interface
 * Predefined methods for http requests.
 */
class ICommunication {
    /**
     * Cannot be created if any of the following methods is undefined.
     */
	constructor() {
		if (new.target === ICommunication) {
			throw new TypeError('Interface, cannot be created.');
		}
		if (this.send === undefined) {
			throw new TypeError('Method "send" not implemented.');
		}
		if (this.download === undefined) {
			throw new TypeError('Method "download" not implemented.');
		}
	}
}

export { ICommunication };