// Dependencies...
import { ICommunication } from '../../ICommunication';
const axios = require('../../../lib').Axios;
const axiosAdapter = require('../../../lib').AxiosAdapter;

// Private props for this class.
const privateProps = new WeakMap();

/**
 * Class for HTTP requests use.
 * @class {Communication}
 */
class Communication extends ICommunication {
    /**
     * Create a http client object
     * @param {string} baseUrl [optional] - Base URL.
     * @param {number} timeout [optional] - Request timeout, default value = 10s.
     */
    constructor(baseUrl = '', timeout = 10000) {

        // Default impl.
        super();

        // Axios options.
        let opcoes = {
            baseURL: baseUrl,
            timeout: timeout,
            adapter: axiosAdapter
        };
        let httpClient = axios.create(opcoes);

        // Private props.
        privateProps.set(this, {
            axios: httpClient,
            baseUrl: baseUrl,
            timeout: timeout
        });
    }

    /**
     * @property {axios} - Return axios instance.
     * @return {axios}
     */
    get axios() {
        return privateProps.get(this).axios;
    }

    /**
     * @property {string} - Returns baseUrl.
     * @return {string}
     */
    get baseURL() {
        return privateProps.get(this).baseURL;
    }

    /**
     * @property {number} - Returns timeout.
     * @return {number}
     */
    get timeout() {
        return privateProps.get(this).timeout;
    }

    /**
     * Send a request to an endpoint.
     * @param {string} endpoint - target.
     * @param {string} method - POST, GET, PUT, DELETE
     * @param {Object} urlParameters [opcional] - url request parameters.
     * @param {Object} body [opcional] - body request parameters (json).
     * @param {Object} headers [opcional] - authorization headers.
     * @param {number} timeout [opcional] - request timeout.
     * @returns {Object}
     */
    async send(endpoint, method, urlParameters = null, body = null, headers = null, timeout) {

        // Config options (initial).
        let opcoes = {
            method: method,
            url: endpoint
        }

        // Url parameters.
        if (urlParameters) {
            let parameters = '';
            for (let key in urlParameters) {
                if (parameters.length > 0) parameters += '&';
                parameters += encodeURIComponent(key) + '=' + encodeURIComponent(urlParameters[key]);
            }
            opcoes.url += '?' + parameters;
        }

        // Body parameters.
        if (body) {
            opcoes.data = body;
        }

        // Headers (authorization).
        if (headers) {
            opcoes.headers = headers;
        }

        // Request timeout.
        if (timeout) {
            opcoes.timeout = timeout;
        }

        // Error treatment.
        try {   // Returning request response.
            return await this.axios(opcoes);
        } catch (err) { // Ops.. error.
            if (err.response) {
                return err.response;
            } else {
                return {
                    status: 500,
                    data: { message: err.message }
                };
            }
        }
    }

    /**
     * Send a download request to and endpoint.
     * @param {string} endpoint - target.
     * @param {string} method - POST, GET, PUT, DELETE
     * @param {Object} urlParameters [opcional] - url request parameters.
     * @param {Object} body [opcional] - body request parameters (json).
     * @param {Object} headers [opcional] - authorization headers.
     * @param {number} timeout [opcional] - request timeout.
     * @returns {Object}
     */
    async download(endpoint, method = 'GET', type = 'arraybuffer', urlParameters = null, body = null, headers = null, timeout = null) {
        try {

            // Config options (initial).
            let opcoes = {
                method: method,
                url: endpoint,
                responseType: type
            }
    
            // Url parameters.
            if (urlParameters) {
                let parametros = '';
                for (let key in urlParameters) {
                    if (parametros.length > 0) parametros += '&';
                    parametros += encodeURIComponent(key) + '=' + encodeURIComponent(urlParameters[key]);
                }
                opcoes.url += '?' + parametros;
            }
    
            // Body parameters.
            if (body) {
                opcoes.data = body;
            }
    
            // Headers (authorization).
            if (headers) {
                opcoes.headers = headers;
            }
    
            // Request timeout.
            if (timeout) {
                opcoes.timeout = timeout;
            }

            // Download request send.
            return await this.axios(opcoes);
            
        // Error treatment.
        } catch (err) {
            if (err.response) {
                return err.response;
            } else {
                return {
                    status: 500,
                    data: { message: err.message }
                };
            }
        }
    }
}

export { Communication };