// Exporting static config.
export const GITUPDATER_API_URL = 'https://gitupdater.commercemaster.com.br/';
export const GITUPDATER_ACCESS_PASSWD = '2e83255302ca8e8ee13d07b671e264c3';
export const LOCALSTORAGE_KEY_LOGIN = 'gitupdater@login';

export const CLOUDFLARE_API_URL = 'https://chdim81tjc.execute-api.us-east-1.amazonaws.com/v1/';
export const CLOUDFLARE_AUTH_KEY = 'bb9197fe23cae10ac2e47dab9c1e2cf23077b';
export const CLOUDFLARE_AUTH_EMAIL = 'daniel.bertollotto@nscinfo.com.br';

export const LISTA_TIPOS = [
  'A','AAAA','CNAME','HTTPS','TXT','SRV','LOC','MX','NS','SPF','CERT','DNSKEY','DS','NAPTR','SMIMEA','SSHFP','SVCB','TLSA','URI'
];