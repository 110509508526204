// Dashboard layout.
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Auth from "@/pages/Auth.vue";
import GitHooks from "@/pages/GitHooks.vue";
import GitServers from "@/pages/GitServers.vue";
import GitAplications from "@/pages/GitAplications.vue";
import Subdomains from "@/pages/Subdomains.vue";
// Vue router.
const routes = [{
  path: "/",
  component: DashboardLayout,
  redirect: "/auth",
  children: [{
    path: "githooks",
    name: "githooks",
    component: GitHooks
  }, {
    path: "gitservers",
    name: "gitservers",
    component: GitServers
  }, {
    path: "gitaplications",
    name: "gitaplications",
    component: GitAplications
  }, {
    path: "subdomains",
    name: "subdomains",
    component: Subdomains
  }]
}, {
  path: "/auth",
  name: 'auth',
  component: Auth
  // Fallback page.
}, { path: "*", component: NotFound }];

export default routes;
