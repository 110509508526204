// Dependencies.
import Notify from "vue-notifyjs";
import SweetAlert from "sweetalert2";
import VTooltip from "v-tooltip";
import SideBar from "@/components/SidebarPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import "es6-promise/auto";

// Libs.
import { Communication } from "@/lib/Communication";
import { Objeto } from "@/lib/Helpers";
//css assets
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/sass/paper-dashboard.scss";
import "@/assets/css/themify-icons.css";
export default {
  install(Vue) {
    // Plugins.
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notify);
    Vue.use(VTooltip);

    // libs.
    Vue.prototype.$communication = Communication;
    Vue.prototype.$objetos = Objeto;
    Vue.prototype.$swal = SweetAlert;
  }
};
