<template>
  <div @keydown.esc="modals.view=false;modals.edit=false;modals.create=false;" class="row">
    <div class="col-md-12">
      <card>
        <!-- Main page title -->
        <template slot="header">
          <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Listagem de servidores</h4>
              <p class="card-category mt-3 mt-lg-0">
                Tabela contendo todos os servidores da NSCValore que armazenam os projetos.
              </p>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
              <base-button @click="createServer" class="btn-primary w-100 mt-lg-1 mt-3">+ Incluir</base-button>
            </div>
          </div>
        </template>
        <!-- Main page content -->
        <div class="content git-hooks pt-3">
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <!-- Tabela para listagem dos servidores -->
          <paper-table :data="dados">
            <!-- Cabeçalho da tabela -->
            <template slot="columns">
              <th class="pointer" v-on:click="ordenar('chave')" >Identificação <i  v-if="ordemSeta === 'chave'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
              <th class="pointer" v-on:click="ordenar('entrypoint')">Entrypoint <i  v-if="ordemSeta === 'entrypoint'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
              <th class="pointer" v-on:click="ordenar('ips')">Qtd. IP's <i  v-if="ordemSeta === 'ips'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
              <th></th>
            </template>
            <!-- Linhas da tabela -->
            <template slot-scope="{row}">
              <td>{{ row.chave }}</td>
              <td v-tooltip="row.entrypoint.length > 40 ? row.entrypoint : false">
                {{row.entrypoint.length > 40 ? row.entrypoint.substring(0, 19).trim() + '...' : row.entrypoint}}
              </td>
              <td>{{ row.ips.length }} IP's configurado(s)</td>
              <td>
                <base-dropdown class="dropdown" position="right">
                  <a 
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ti-menu-alt"></i>
                  </a>
                  <template>
                    <!-- View option -->
                    <a href="javascript:void(0)" class="dropdown-item" @click="viewServer(row)">
                      <i class="ti-eye mr-2"></i>Visualizar
                    </a>
                    <!-- Edit option -->
                    <a href="javascript:void(0)" class="dropdown-item" @click="editServer(row)">
                      <i class="ti-pencil mr-2"></i>Editar
                    </a>
                  </template>
                </base-dropdown>
              </td>
            </template>
          </paper-table>
        </div>
      </card>
    </div>
    <!-- Modal de visualizar git hook -->
    <base-modal
      :show.sync="modals.view"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Detalhes do Servidor</h4>
          </div>
          <div class="col">
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>Informações</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Identificação e tipo -->
            <div class="row mb-2">
              <div class="col-12">
                <span class="category"><strong>Nome:</strong> {{ servidorAtual.chave }}</span>
              </div>
              <div class="col-12">
                <span class="category"><strong>Identificação:</strong> {{ servidorAtual.entrypoint }}</span>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>IP's</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Comandos de atualização -->
            <div class="row mb-2">
              <div class="col-12 d-flex flex-column">
                <div v-for="(server, i) in servidorAtual.ips" :key="i" class="category mb-3">
                  <span class="category"><strong>Endereço:</strong> {{ server.ip }}</span><br>
                  <span class="category"><strong>Tipo:</strong> {{ server.tipo }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </base-card>
    </base-modal>
    <!-- Modal para criar servidor -->
    <base-modal
      :show.sync="modals.create"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Incluir Servidor</h4>
          </div>
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <form role="form">
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>Informações</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Nome e entrypoint -->
            <div class="row">
              <div class="col-6">
                <base-input label="Identificação" v-model="servidorAtual.chave" placeholder='Identificação'></base-input>
              </div>
              <div class="col-6">
                <base-input label="Entrypoint" v-model="servidorAtual.entrypoint" placeholder='Entrypoint' @blur="colocarBarra"></base-input>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>IP's</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Lista de IP's -->
            <div class="row mb-2">
              <div class="col-12">
                 <!-- Update commands dinamic fields -->
                <div v-for="(ip, i) in servidorAtual.ips" :key="i" class="col-12 d-flex justify-content-between p-0">
                  <div class="col-6 p-0">
                    <base-input label="Endereço" placeholder='Endereço' v-model="ip.ip"/>
                  </div>
                  <div class="col-4 p-0">
                    <base-input label="Tipo" class="ml-4" placeholder='Tipo' v-model="ip.tipo"/>
                  </div>
                  <div class="col-2 p-0 d-flex justify-content-end align-items-end">
                    <base-button class="btn-danger btn-mb-reg mb-3" @click="deleteIp(i)">Remover</base-button>
                  </div>
                </div>
                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addIp">
                    + Novo IP
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>Ações</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Botão para atualizar githook -->
            <div class="row">
              <div class="col-12">
                <base-button @click="saveServer" class="btn-secondary w-100">Criar</base-button>
              </div>
            </div>
          </form>
        </template>
      </base-card>
    </base-modal>

    <!-- Modal para editar servidor -->
    <base-modal
      :show.sync="modals.edit"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="px-lg-5 py-lg-5 mb-0"
        class="border-0"
      >
        <template v-if="!deleteId">
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Editar Servidor</h4>
          </div>
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <form class="form">
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>Informações</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Nome e entrypoint -->
            <div class="row">
              <div class="col-6">
                <base-input label="Identificação" v-model="servidorAtual.chave" placeholder='Identificação'></base-input>
              </div>
              <div class="col-6">
                <base-input label="Entrypoint" v-model="servidorAtual.entrypoint" placeholder='Entrypoint' @blur="colocarBarra"></base-input>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>IP's</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Lista de IP's -->
            <div class="row mb-2">
              <div class="col-12">
                 <!-- Update commands dinamic fields -->
                <div v-for="(ip, i) in servidorAtual.ips" :key="i" class="col-12 d-flex jstify-content-between p-0">
                  <div class="col-6 p-0">
                    <base-input label="Endereço" placeholder='Endereço' v-model="ip.ip"/>
                  </div>
                  <div class="col-4 p-0">
                    <base-input label="Tipo" class="ml-4" placeholder='Tipo' v-model="ip.tipo"/>
                  </div>
                  <div class="col-2 p-0 d-flex justify-content-end align-items-end">
                    <base-button class="btn-danger btn-mb-reg mb-3" @click="deleteIp(i)">Remover</base-button>
                  </div>
                </div>
                <!-- Button for adding more update commands -->
                <div class="d-flex justify-content-center">
                  <base-button class="btn-primary w-100" @click="addIp">
                    + Novo IP
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Divisor do formulários -->
            <div class="row mb-2">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="small text-uppercase p-0 m-0 mr-3 category"><strong>Ações</strong></p>
                <hr class="w-100"/>
              </div>
            </div>
            <!-- Botão para atualizar githook -->
            <div class="row">
              <div class="col-12">
                <base-button @click="saveServer" class="btn-secondary w-100 mb-2">Salvar</base-button>
                <base-button  @click="deleteId=servidorAtual.chave" class="btn-danger w-100">Excluir</base-button>
              </div>
            </div>
          </form>
        </template>
        <template v-else>
          <!-- Título da modal -->
          <div class="text-center text-muted mb-4">
            <h4 class="my-0">Remover GitHook</h4>
            <span>Tem certeza? Ao remover os dados não será possível retornar!</span>
          </div>
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>
          <!-- Botões de confirmação da modal -->
          <div class="text-center text-muted">
            <div class="row justify-content-center">
              <base-button class="mr-2" @click="deleteServer" type="success">Sim</base-button>
              <base-button @click="deleteId=null;" type="danger">Não</base-button>
            </div>
          </div>
        </template>
      </base-card>
    </base-modal>
  </div>
</template>

<script>
import { PaperTable } from "@/components";
import { GITUPDATER_API_URL,LOCALSTORAGE_KEY_LOGIN } from '@/config/data';

export default {
  beforeMount() {
    // Auth...
    let login = localStorage.getItem(LOCALSTORAGE_KEY_LOGIN); 
    if (!login || login != 'login@gitupdater#5550123') {
      this.$router.push({ name: "auth" });
    }
    // Services...
    this.comunicacao = new this.$communication(GITUPDATER_API_URL);
    this.init();
  },
  components: { PaperTable },
  computed: {
    tableData: function() {
      let chaves = Object.keys(this.servidores);
      let data = {};
      let retorno = [];
      for (let i in chaves) {
        data[chaves[i]] = this.servidores[chaves[i]];
        data[chaves[i]].chave = chaves[i];
        retorno.push(data[chaves[i]]);
      }
      return retorno;
    }
  },
  data: () => ({
    comunicacao: null,
    deleteId: null,
    error: false,
    modals: {
      create: false,
      edit: false,
      view: false
    },
    msgError: null,
    servidorAtual: {
      chave: '',
      ips: [],
      entrypoint: ''
    },
    servidores: {},
    dados: [],
    ordem: 1,
    ordemAtual: '',
    ordemSeta: 'chave',
    
  }),
  methods: {
    colocarBarra(){
      if (this.servidorAtual.entrypoint.match(/\/$/) == null) {
        this.servidorAtual.entrypoint += '/'
      }
    },
    addIp: function () {
      this.servidorAtual.ips.push({ ip: '', tipo: ''});
    },
    createServer: function() {
      // Limpando dados do formulario.
      this.clearServer();
      this.deleteId = null;
      this.modals.create = true;
    },
    clearServer: function() {
      this.servidorAtual = {
        chave: '',
        ips: [],
        entrypoint: ''
      }
    },
    deleteIp: function (index) {
      this.servidorAtual.ips.splice(index, 1);
    },
    deleteServer: async function() {
      let resp = await this.comunicacao.send('/configuracoes/apagarServidor', 'DELETE', null, {
        servidor: this.deleteId
      });
      if (resp.status == 200) {
        this.clearServer();
        await this.init();
        this.modals.edit = false;
      } else {
        this.error = true;
        this.msgError = resp.data.mensagem || 'Ocorreu um erro desconhecido durante a requisição!';
      }
    },
    editServer: function(server) {
      // Formating update data.
      let formattedIps = server.ips.map(u => { return { ip: u.ip, tipo: u.tipo } });
      this.servidorAtual = { ...server };
      //Formatting atual server.
      this.servidorAtual.ips = formattedIps;
      this.deleteId = null;
      this.modals.edit = true;
    },
    init: async function() {
      let resp = await this.comunicacao.send('/configuracoes/servidores', 'GET');
      if (resp.status == 200) {
        this.servidores = resp.data.retorno;
        this.getDados();
      } else {
        this.error = true;
        this.msgError = 'Ocorreu um erro durante a recuperação dos servidores no endpoint do GitUpdater! Por favor tente novamente.';
      }
    },
    saveServer: async function() {
      // Validando informacoes.
      if (!this.servidorAtual.chave || this.servidorAtual.chave.length == 0) this.error = true;
      if (!this.servidorAtual.entrypoint || this.servidorAtual.entrypoint.length == 0) this.error = true;
      if (!this.servidorAtual.ips || this.servidorAtual.ips.length == 0) this.error = true;
      if (this.error) {
        this.msgError = 'Há erros de válidação na sua requisição! Por favor preencha os dados corretamente.';
        return;
      }
      // Objeto para requisicao.
      let dados = {
        servidor: this.servidorAtual.chave,
        dados: {
          ips: this.servidorAtual.ips,
          entrypoint: this.servidorAtual.entrypoint
        }
      }
      // Cliente http.
      let resp = await this.comunicacao.send('/configuracoes/gravarServidor', 'POST', null, dados);
      if (resp.status == 201 || resp.status == 200) {
        this.clearServer();
        await this.init();
        this.modals.create = false;
        this.modals.edit = false;
      } else {
        this.error = true;
        this.msgError = resp.data.mensagem || 'Ocorreu um erro desconhecido durante a requisição!';
      }
    },
    viewServer: function(server) {
      this.servidorAtual = { ...server };
      this.deleteId = null;
      this.modals.view = true;
    },
    getDados: function() {
      let chaves = Object.keys(this.servidores);
      let data = {};
      let retorno = [];
      for (let i in chaves) {
        data[chaves[i]] = this.servidores[chaves[i]];
        data[chaves[i]].chave = chaves[i];
        retorno.push(data[chaves[i]]);
      }
      this.dados = retorno;
      this.execOrdenar();
    },
    ordenar(key){
      this.ordemSeta = key;
      if(this.ordemAtual === key){
        this.ordem = -1;
        this.ordemAtual = '';
      }else{
        this.ordem = 1;
        this.ordemAtual = key;

      }
      if(key === 'ips'){
        let ordemIpValue = (this.ordem == 1) ? -1 : 1;
        this.dados = this.dados.sort((a,b)=>{
            if(a['ips'].length > b['ips'].length){
               return this.ordem; 
            }
            if(a['ips'].length < b['ips'].length){
               return ordemIpValue; 
            }
            return 0;
        })
      }else{
        this.dados = this.dados.sort(
        this.$objetos.ordernarArray(
          key,
          this.ordem,
          )
        );
      }
      
    },
    execOrdenar(){
        if(this.ordemSeta){
          var key = this.ordemSeta;
          this.ordemSeta = '';
          
          if(this.ordem === -1){
            this.ordemAtual = key;
          }else{
            this.ordemAtual = '';
          }
          this.ordenar(key)
        }
      }
  
  },
  name: "Gitservers",
  watch: {
    error: function(error) {
      if (error) {
        setTimeout(() => {
          this.error = false;
          this.msgError = null;
        }, this.msgError.length * 75);
      }
    }
  }
};
</script>
<style scoped>
  .pointer{
    cursor: pointer;
  }
</style>
