/**
 * Classe de manipulação de objetos
 */
class Objeto{
    /** Manipulacao de Objetos */

    /**
     * Mergeia dois objetos JSON
     * @param {Object} a Objeto A
     * @param {Object} b Objeto B
     */
    static leftMerge(a, b) {
        const res = {};
        for (const p in a) res[p] = (p in b ? b : a)[p];
        return res;
    }
    /**
     * Limpa propriedades nulas dentro de um objeto
     * @param {Object} obj Objeto a ser limpado
     */
    static clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    }

    static ordernarArray(propriedade, ordem) {
        if(propriedade[0] === "-") {
            ordem = -1;
            propriedade = propriedade.substr(1);
        }
        // eslint-disable-next-line
        return function (a,b) {

            a = a[propriedade] ? a[propriedade] : eval('a.'+propriedade);
            b = b[propriedade] ? b[propriedade] : eval('b.'+propriedade);

            if (typeof a == 'number' && typeof b == 'number') {
                var resultadoNumerico = (a < b) ? -1 : (a > b) ? 1 : 0;
                return resultadoNumerico * ordem;
            }

            if (a && b) {
                a = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                b = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            }

            var resultado = (a < b) ? -1 : (a > b) ? 1 : 0;
            return resultado * ordem;
        }
    }    
    /**
     * Filtra numericamente um objeto usando o parametro de pesquisa nos campos selecionados
     * @param {Object} dados Objeto de dados da tabela
     * @param {String} pesquisa String de pesquisa numerica
     * @param {Array} campos Campos a serem filtrados dentro do objeto
     */
    static filtrarNumero(dados, pesquisa, campos) {
        let pesquisaNum = pesquisa.replace(/[^\d.-]/g, "");
        if (pesquisaNum.length == pesquisa.length) {
            for (let i in campos) {
                if (dados[campos[i]].startsWith(pesquisaNum)) {
                    return true;
                }
            }
        }
        return false;
    }
    /**
     * Filtra  um objeto usando o parametro de pesquisa nos campos selecionados
     * @param {Object} dados Objeto de dados da tabela
     * @param {String} pesquisa String de pesquisa
     * @param {Array} campos Campos a serem filtrados dentro do objeto
     */
    static filtrarTexto(dados, pesquisa, campos) {
        if (pesquisa.length) {
            for (let i in campos) {
                if (dados[campos[i]].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(pesquisa.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    /**
     * Filtro de pesquisa que aciona o FiltrarTexto() e FiltrarNumero()
     * @param {Object} data Objeto
     * @param {Object} opcoes Opções {pesquisa: "", camposTexto: [], camposNumero: []}
     */
    static filtroPesquisa(data, opcoes) {
        let opcoesPadrao = {
            pesquisa: "", camposTexto: [], camposNumero: []
        }
        opcoes = this.leftMerge(opcoesPadrao, opcoes);

        if (this.filtrarTexto(data, opcoes.pesquisa, opcoes.camposTexto)) {
            return true;
        }
        if (this.filtrarNumero(data, opcoes.pesquisa, opcoes.camposNumero)) {
            return true;
        }

        return false;
    }
}
export default Objeto;