<template>
  <div
    class="form-group"
    :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'focused': focused},
       {'has-label': label || $slots.label},
       {'has-success': valid === true},
       {'has-danger': valid === false}
       ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{label}}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">

      <input v-if="mask"
        :value="value"
        v-on="listeners"
        v-mask="mask"
        :masked="masked"
        v-bind="$attrs"
        class="form-control"
        :class="[
                     {'is-valid': valid === true},
                     {'is-invalid': valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
      />
      <input v-else
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
                     {'is-valid': valid === true},
                     {'is-invalid': valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    
    <div v-if="secondaryButton && secondaryButtonIcon" class="input-group-append">
      <span class="input-group-text secondaryButton" @click="secondaryButtonClick()">
          <i :class="secondaryButtonIcon"></i>
      </span>
    </div>
    
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block;"
        :class="{'mt-2': hasIcon}"
        v-if="error"
      >{{ error }}</div>
    </slot>
  </div>
</template>
<script>
import { mask, masked } from "vue-the-mask";

export default {
  directives: {mask, masked},
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    secondaryButton: {
      type: Boolean,
      default: false,
    },
    secondaryButtonIcon: {
      type: String,
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    value: {
      type: [String, Number],
      description: "Input value",
      default: ""
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon"
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon"
    },
    mask: {
      type: [String, Array],
      description: "Mask"
    },
    masked: {
      type: [Boolean],
      description: "Mask"
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    secondaryButtonClick(){
      this.$emit("secondaryButtonClick");
    }
  }
};
</script>
<style>
.secondaryButton{
  cursor: pointer;
  transition: color ease 0.2s;
}
.secondaryButton:hover{
  color: rgb(22, 13, 13);
}
</style>
