<template>
  <div @keydown.esc="modals.view=false;" class="row">
    <div class="col-md-12">
      <card>
        <!-- Main page title -->
        <template slot="header">
          <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 pt-4">
              <h4 class="card-title">Listagem de Aplicações</h4>
              <p
                class="card-category mt-3 mt-lg-0"
              >Tabela contendo todos as Aplicações da NSCValore que armazenam os projetos.</p>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-3">
              <label style="padding-left: 18px;">Selecionar um servidor</label>
              <div class="form-control h-auto">
                <select v-model="servidorAtual" class="w-100" @change="preventToaster">
                  <option :value="null" disabled selected>Selecionar um servidor</option>
                  <option v-for="(server, i) in servidores" :key="i" :value="server">{{i}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3 pt-4">
              <div class="text-center log-server">
                <button class="btn btn-default"
                  @click="attLogs(logSlave)"
                  >Log
                </button>
              </div>
            </div>
          </div>
        </template>
        <!-- Main page content -->
        <div class="content git-hooks pt-3">
          <!-- Em casos de erro de requisição -->
          <base-alert type="danger" v-show="error">
            <h2 class="text-white mt-1">Ops...</h2>
            <span class="text-white" v-html="msgError"></span>
          </base-alert>

          <div class="text-center"  v-show="changing">


              <i class="ti-reload d-inline-block" id="loading"></i>


          </div>
          <!-- Tabela para listagem dos aplicacoes -->
          <paper-table :data="dados">
            <!-- Cabeçalho da tabela -->
            <template slot="columns">
              <th class="pointer"  v-on:click="ordenar('pid')">Pid <i  v-if="ordemSeta === 'pid'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
              <th class="pointer" v-on:click="ordenar('nome')">Nome <i  v-if="ordemSeta === 'nome'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
              <th class="pointer" v-on:click="ordenarStatus('status')">Status <i  v-if="ordemSeta === 'status'" :class="'ti-arrow-'+ (ordem === 1 ? 'up' : 'down') "></i></th>
            </template>
            <!-- Linhas da tabela -->
            <template slot-scope="{row}">
              <td>{{row.pid}}</td>
              <td>{{row.nome}}</td>
              <td class="text-center" v-if="row.status == 'loading'"><i class="ti-reload d-inline-block" id="loading"></i></td>
              <td v-else>{{row.pid ? "Em execução" : "Parado"}}</td>
              <td>
                <base-dropdown class="dropdown" position="right" v-if="row.status != 'loading'">
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ti-menu-alt"></i>
                  </a>
                  <template>
                    <!-- View option -->
                    <a
                      href="javascript:void(0)"
                      v-if="!row.pid"
                      @click="initApplication(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-check mr-2"></i>Iniciar
                    </a>
                    <!-- Edit option -->
                    <a
                      href="javascript:void(0)"
                      v-if="row.pid"
                      @click="stopApplication(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-na mr-2"></i>Parar
                    </a>

                    <a
                      href="javascript:void(0)"
                      v-if="row.pid"
                      @click="restartApplication(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-reload mr-2"></i>Reinicializar
                    </a>

                    <a
                      href="javascript:void(0)"
                      @click="updateHook(row)"
                      class="dropdown-item"
                    >
                      <i class="ti-angle-double-up mr-2"></i>Atualizar
                    </a>

                    <a
                      href="javascript:void(0)"
                      @click="attLogs(row)"

                      class="dropdown-item"
                    >
                      <i class="ti-view-list mr-2"></i>Logs
                    </a>

                    <!-- None Option  -->

                  </template>
                </base-dropdown>
              </td>
            </template>
          </paper-table>
        </div>
      </card>
      <base-modal
      :show.sync="modals.view"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
      type="mini"
    >
      <base-card
        shadow
        type=""
        header-classes="pb-5"
        body-classes="mb-0"
        class="border-0"

      >
        <template>
          <!-- Título da modal -->

          <div class="col">
            <!-- Divisor do formulários -->

            <!-- Identificação e tipo -->
            <div class="row mb-2" id="logs">
                <pre class="log-breakline">{{logs}}</pre>

            </div>
            <!-- Divisor do formulários -->

            <!-- Comandos de atualização -->


          </div>
          <div class="row">
            <div class="col-md-8 align-items-center text-info">
              {{ logAtual }}
            </div>
            <div class="col-md-4">
              <div class="text-right">
                <button class="btn btn-primary"
                  @click= "download()"
                  >Download
                </button>
              </div>
            </div>
          </div>
        </template>
      </base-card>
    </base-modal>
    </div>
  </div>
</template>

<script>
import { PaperTable } from "@/components";
import { GITUPDATER_API_URL, LOCALSTORAGE_KEY_LOGIN } from "@/config/data";

export default {
  beforeMount() {
    // Auth...
    let login = localStorage.getItem(LOCALSTORAGE_KEY_LOGIN);
    if (!login || login != "login@gitupdater#5550123") {
      this.$router.push({ name: "auth" });
    }
    // Services...
    this.comunicacao = new this.$communication(GITUPDATER_API_URL);
    this.init();
  },
  components: { PaperTable },
  computed: {
  },
  data: () => ({
    comunicacao: null,
    error: false,
    msgError: null,
    changing: false,
    msgChanging: null,
    servidores: [],
    servidorAtual: null,
    aplicacoes: [],
    initialScroll: true,
    logAtual: '',
    logs: null,
    logSlave: {
      nome: 'GIT_UpdaterSlave'
    },
    modals: {
      view: false
    },
    dados: [],
    ordem: 1,
    ordemSeta: 'nome',
    loading: false,
    hookAtual: {
      chave: "",
      aplicacao: false,
      nodes: "max",
      update: [],
      create: [],
      inicializador: null,
      diretorio: "",
      servidor: "",
      env: null,
      inativo: false
    },

  }),
  methods: {
    updateHook: async function(row) {
      let nomeRepositorio = row.nome;

      this.loading = true;

      let req = await this.comunicacao.send(
        this.servidorAtual.entrypoint + "atualizador/atualizar",
        "POST",
        null,
        { nomeRespositorio: nomeRepositorio }

      );

      if (req.status == 200) {
        this.loading = false;
        this.fireToast("success", "Hook atualizado com sucesso");
      } else {
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }
    },
    fireToast(icon, title, toastTimeDuration = 3000) {
      const Toast = this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: toastTimeDuration,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          }
        })
        .fire({ icon, title });
    },
    initApplication: async function(row) {
      let nome = row.nome;
      row.status = 'loading'
      let req = await this.comunicacao.send(
        this.servidorAtual.entrypoint + "aplicacoes/iniciar/",
        "POST",
        null,
        { nome: nome }
      );
      if (req.status == 200) {
        setTimeout(() => {
          this.changeServer(this.servidorAtual);
        }, 3000)
      } else {
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }

    },

    fireToast(icon, title, toastTimeDuration = 3000) {
      const Toast = this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: toastTimeDuration,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          }
        })
        .fire({ icon, title });
    },

    preventToaster() {
      this.fireToast("info", "Filtro alterado com sucesso");
    },

    verifyFiltroCache() {
      // Get LocalStorage
      let filtroFromLocalStorage = localStorage.getItem("filtroAplicacao");
      // Caso tenha filtro do localStorage, temos que comparar eles
      // pra ver se tem algo que está no local storage e não na instância do Vue, assim o toast
      // de filtro alterado não aparece atoa
      if (filtroFromLocalStorage) {
        filtroFromLocalStorage = JSON.parse(filtroFromLocalStorage);
          if (filtroFromLocalStorage !== this.servidorAtual) {
            this.servidorAtual = filtroFromLocalStorage;
          } else {
        localStorage.setItem("filtroAplicacao", JSON.stringify(this.servidorAtual));
       }
      }
    },

    changeServer: async function(value) {

      if (value) {

        this.msgError = "";
        this.error= false;
        this.changing = true;
        this.msgChanging= "Fazendo requisição...";
        this.dados = [];
        let req = await this.comunicacao.send(
          value.entrypoint + "aplicacoes",
          "GET"
        );
        if (req.status == 200) {
          this.aplicacoes = req.data.retorno;
          this.getDados();
          this.execOrdenar();
          this.changing = false;
          this.msgChanging= "";
        } else {
          this.aplicacoes = [];
          this.changing = false;
          this.execOrdenar();
          this.msgChanging= "";
          this.error = true;
          this.msgError =
            `Ocorreu um erro durante a recuperação das aplicacões no endpoint: ${value.entrypoint} do GitUpdater! Por favor tente novamente.`;
          this.dados = [];
        }
      }
    },
    stopApplication: async function(row) {
      let nome = row.nome;
      row.status = "loading"
      let req = await this.comunicacao.send(
        this.servidorAtual.entrypoint + "aplicacoes/parar/",
        "POST",
        null,
        { nome: nome },
        null,
        15000
      );
      if (req.status == 200 || req.status == 500) {
          this.changeServer(this.servidorAtual);
      } else {
        this.error = true;
        this.dados = [];
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }
    },

    restartApplication: async function(row){
      let nome = row.nome;
      row.status = "loading"

      // Parando aplicação
      let req = await this.comunicacao.send(
        this.servidorAtual.entrypoint + "aplicacoes/parar/",
        "POST",
        null,
        { nome: nome },
        null,
        15000
      );
      if (!(req.status == 200 || req.status == 500)) {
        this.error = true;
        this.dados = [];
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
          return;
      }

      //Iniciando aplicação
      let req2 = await this.comunicacao.send(
        this.servidorAtual.entrypoint + "aplicacoes/iniciar/",
        "POST",
        null,
        { nome: nome }
      );
      if (req2.status == 200) {
        setTimeout(() => {
          this.changeServer(this.servidorAtual);
        }, 3000)
      } else {
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }
    },

    updateApplication: async function(row) {},
    init: async function() {
      let server = await this.comunicacao.send(
        "/configuracoes/servidores",
        "GET"
      );
      if (server.status == 200) {
        this.servidores = server.data.retorno;
        this.servidorAtual = this.servidores[Object.keys(server.data.retorno)[0]];
        this.verifyFiltroCache();
      } else {
        this.servidorAtual = null;
        this.error = true;
        this.msgError =
          "Ocorreu um erro durante a recuperação das aplicacões no endpoint do GitUpdater! Por favor tente novamente.";
      }

    },

    timeout: function (ms) {
      if (this.modals.view) {
        return new Promise(resolve => setTimeout(resolve, ms))
      }
    },

    attLogs: async function(row) {
      // Resetando valores
      this.initialScroll = true
      this.logs = ''
      this.logAtual = ''

      // Iniciando a modal e o loop para atualizar o log
      this.modals.view = true
      while (this.modals.view) {
        await this.getLogs (row, (nome, logs) => {
          this.logAtual = nome
          this.logs = logs
        })

        // Iniciando o scroll no final do log quando ativado pela primeira vez
        if (this.initialScroll) {
          var logDiv = document.getElementById("logs")
          logDiv.scrollTop = logDiv.scrollHeight
          this.initialScroll = false
        }

        // Delay para a próxima requisição
        await this.timeout(2000)
      }
    },

    getLogs: async function(row, callback) {
      let req = await this.comunicacao.send (
        this.servidorAtual.entrypoint + "logs",
        "POST",
        null,
        {nome: row.nome, tamanho: 750}
      )
      if (req.data.retorno) {
        var nome = row.nome
        var logs = req.data.retorno.conteudo
        callback(nome, logs)
      } else {
        callback("-", "Falha ao obter o Log")
      }
    },

   getDados: function() {
      let chaves = Object.keys(this.aplicacoes);

      let data = {};
      let retorno = [];
      for (let i in chaves) {
        data[chaves[i]] = this.aplicacoes[chaves[i]];
        data[chaves[i]].chave = chaves[i];
        retorno.push(data[chaves[i]]);
      }
      this.dados = retorno;


  },
  download: function(){
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.logs));
      element.setAttribute('download', 'log.txt');

      element.style.display = 'none';
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },

  ordenar(key){

    if(this.ordemAtual === key){
      this.ordem = -1;
      this.ordemAtual = '';
      this.ordemSeta = key;
    }else{
      this.ordem = 1;
      this.ordemAtual = key;
      this.ordemSeta = key;
    }
     this.dados = this.dados.sort(
       this.$objetos.ordernarArray(
         key,
         this.ordem,
       )
     );
   },
   ordenarStatus(key){

     if(this.ordemAtual === key){
      this.ordem = -1;
      this.ordemAtual = '';
      this.ordemSeta = key;
    }else{
      this.ordem = 1;
      this.ordemAtual = key;
      this.ordemSeta = key;
    }
     this.dados = this.dados.sort((a,b)=>{
       if(a.pid && !b.pid){
         return this.ordem;
       }
       if(!a.pid && b.pid){
         return this.ordem === 1 ? -1: 1;
       }
     });

   },

    execOrdenar(){
        if(this.ordemSeta){
          var key = this.ordemSeta;
          this.ordemSeta = '';

          if(this.ordem === -1){
            this.ordemAtual = key;
          }else{
            this.ordemAtual = '';
          }
          if(key === 'status'){
            this.ordenarStatus(key);
          }else{
            this.ordenar(key);
          }
        }
      }

  },
  name: "Gitapplication",
  watch: {
    servidorAtual: async function(value) {
      this.changeServer(value);
      localStorage.setItem("filtroAplicacao", JSON.stringify(this.servidorAtual))
    },
    error: function(error) {
      if (error) {
        setTimeout(() => {
          this.error = false;
          this.msgError = null;
        }, this.msgError.length * 75);
      }
    },

    loading(newValue) {
      if (newValue) {
        this.fireToast("info", "Carregando...", 15000);
      }
    },
  }
}

</script>

<style scoped>
select {
    background-color: white;
    border-width: 2px;
    height: 41px;
    border-radius: 20px;

}

.log-server {
  margin-top: 6px;
}

option {
 padding-right: 30px;
}

#logs{
  overflow-y: scroll;
  height: calc(100vh - 150px) !important;
  background-color: black;
}

#logs pre{
  color: white !important;
}

.pointer{
  cursor: pointer;
}

#loading{
  transform: rotate(0deg);
  animation-name: girar;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {

  to {
    transform: rotate(360deg) ;

    }
  }

@media (min-width: 1200px){
>>> .modal-xl {
    max-width: none;
    width: 100%;
    padding: 0 20px;
  }
>>> .card .card-body {
    padding: 1px 4px 10px 4px;
  }
}
</style>
