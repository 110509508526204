<template>
  <div class="wrapper">
    <side-bar>
      <!-- Sidebar links -->
      <template slot="links">
        <sidebar-link to="/githooks" name="Git Hooks" icon="ti-github"/>
        <sidebar-link to="/gitservers" name="Git Servidores" icon="ti-server"/>
        <sidebar-link to="/gitaplications" name="Git Aplicações" icon="ti-desktop"/>
        <sidebar-link to="/subdomains" name="Subdomínios" icon="ti-world"/>
      </template>
      <!-- Menu mobile -->
      <mobile-menu>
        <!-- Não usado -->
        <drop-down
          v-if="false"
          class="nav-item"
          title="Perfil"
          title-classes="nav-link"
          icon="ti-user">
          <a class="dropdown-item">Sair</a>
        </drop-down>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <!-- Main panel -->
    <div class="main-panel">
      <!-- Navbar -->
      <top-navbar/>
      <!-- Content -->
      <dashboard-content @click.native="toggleSidebar"/>
      <!-- Footer -->
      <content-footer/>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
